<template>
  <div class="bg-white px-25 py-25 md:px-65 md:py-40 rounded-5">
    <div>
      <div class="mb-40">
        <h1 class="text-dark-cl-20 text-1xl sm:text-3xl mb-15">Forgot your password?</h1>
        <p class="text-grey-fp-60 text-sm sm:text-base">Enter your email below.</p>
      </div>

      <div>
        <form
          data-test="forgot-password-form"
          @submit.prevent="onSubmit"
        >
          <AppInput
            v-model="v$.email.$model"
            name="email"
            label="Email Address"
            class="mb-30"
            :size="screen.width < 640 ? 'medium' : 'large'"
            :error="errorMessages.email"
            @focus="clearErrorField('email', errorMessages)"
            @blur="validateField('email')"
          />

          <AppButton
            type="primary"
            :size="screen.width < 640 ? 'medium' : 'large'"
            class="w-full mb-20"
          >
            Submit
          </AppButton>

          <div class="flex items-center justify-center text-sm sm:text-base">
            <p class="text-grey-fp-60">
              Back to <router-link
                :to="{ name: 'Login' }"
                class="text-primary"
              >
                log in
              </router-link>
            </p>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, reactive, computed } from 'vue';
  import { useRouter } from 'vue-router';
  import { useVuelidate } from "@vuelidate/core";
  import { useScreen } from 'vue-screen';

  import AppInput from '@/components/stateless/AppInput.vue';
  import AppButton from '@/components/stateless/AppButton.vue';

  import { IErrorFields } from '@/types';
  import { emailRules } from './auth-validation-rules';
  import { clearErrorField, handleSetErrors, validateField } from '@/core/helper-functions';
  import { authService } from '@/services';
  import { routesNames } from '@/router';

  export default defineComponent({
    name: 'ForgotPassword',

    components: { AppInput, AppButton },

    setup() {
      const router = useRouter();
      const state = reactive<{ email: string }>({ email: '' });
      const errorMessages = reactive<IErrorFields>({ email: '' });
      const rules = computed<any>(() => emailRules);
      const v$ = useVuelidate(rules, state);

      const screen = useScreen();

      async function onSubmit() {
        if (await v$.value.$validate()) {
          authService.forgotPassword(state.email)
            .then(() => router.push({ name: routesNames.confirmForgotPassword, params: { email: state.email } }))
            .catch((err) => { errorMessages.email = !err.message ? err : err.message; });
        } else {
          handleSetErrors(v$.value.$errors, errorMessages);
        }
      }

      return {
        errorMessages,
        clearErrorField,
        state,

        screen,
        v$,

        onSubmit,
        validateField: (name: string) => validateField(name, v$, errorMessages),
      };
    }
  });
</script>