import { required, email, helpers, minLength } from "@vuelidate/validators";
import { validateLength, containsUppercase, containsNumber } from '@/core/helper-functions';

const emailRules = {
  email: { 
    required: helpers.withMessage('This field cannot be empty', required),
    email: helpers.withMessage('Email address is not valid', email)
  },
};

const loginFormRules = {
    ...emailRules,
    password: { 
      required: helpers.withMessage('This field cannot be empty', required),
    }
};

const signUpValidationRules = {
  ...emailRules,
  firstName: {
    required: helpers.withMessage('This field cannot be empty', required),
  },
  lastName: {
    required: helpers.withMessage('This field cannot be empty', required),
  },
  zipCode: {
    validateZipCode: helpers.withMessage('Invalid ZipCode', validateLength(5))
  },
  phone: {
    validatePhoneNumber: helpers.withMessage('Invalid Phone number', validateLength(17))
  },
  password: {
    minLength: helpers.withMessage('Password should contain minimum 8 characters', minLength(8)),
    containsUppercase: helpers.withMessage('Password should contain at least one capital letter', containsUppercase),
    containsNumber: helpers.withMessage('Password should contain at least one number', containsNumber),
    required: helpers.withMessage('This field cannot be empty', required)
  }
};

const verificationFormRules = {
  verificationCode: {
    required: helpers.withMessage('This field cannot be empty', required) 
  }
};

export {
  emailRules,
  loginFormRules,
  signUpValidationRules,
  verificationFormRules
}; 