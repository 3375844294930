
  import { defineComponent, reactive, computed } from 'vue';
  import { useRouter } from 'vue-router';
  import { useVuelidate } from "@vuelidate/core";
  import { useScreen } from 'vue-screen';

  import AppInput from '@/components/stateless/AppInput.vue';
  import AppButton from '@/components/stateless/AppButton.vue';

  import { IErrorFields } from '@/types';
  import { emailRules } from './auth-validation-rules';
  import { clearErrorField, handleSetErrors, validateField } from '@/core/helper-functions';
  import { authService } from '@/services';
  import { routesNames } from '@/router';

  export default defineComponent({
    name: 'ForgotPassword',

    components: { AppInput, AppButton },

    setup() {
      const router = useRouter();
      const state = reactive<{ email: string }>({ email: '' });
      const errorMessages = reactive<IErrorFields>({ email: '' });
      const rules = computed<any>(() => emailRules);
      const v$ = useVuelidate(rules, state);

      const screen = useScreen();

      async function onSubmit() {
        if (await v$.value.$validate()) {
          authService.forgotPassword(state.email)
            .then(() => router.push({ name: routesNames.confirmForgotPassword, params: { email: state.email } }))
            .catch((err) => { errorMessages.email = !err.message ? err : err.message; });
        } else {
          handleSetErrors(v$.value.$errors, errorMessages);
        }
      }

      return {
        errorMessages,
        clearErrorField,
        state,

        screen,
        v$,

        onSubmit,
        validateField: (name: string) => validateField(name, v$, errorMessages),
      };
    }
  });
